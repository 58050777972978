import React from "react"
import { Seo, Layout, Section, Container } from "../components"

function Termsofserivce() {
  return (
    <Layout>
      <Seo
        title="Our Terms of Service"
        description="Learn more about ShipX's terms of service."
        slug="terms-of-service"
      />

      <Section padding="lg">
        <Container padding="global-md" classes="bg-white">
          <div className="px-12 space-y-6">
            <center>
              <h1>ShipX Inc. Terms & Conditions</h1>
            </center>
            <p>
              Terms and Conditions for Arranging Customer Onsite Delivery. ShipX
              Inc. is a broker of services to consumers on behalf of certain
              shippers relating to the delivery of products and materials
              purchased by consumers from ShipX Inc.’s shipper relationships
              (the “Services”). These Terms and Conditions explain ShipX Inc.’s
              obligations to you (“Customer”) and your agreements with ShipX
              Inc. This agreement is between you, the original purchaser of the
              product, and ShipX Inc.
            </p>{" "}
            <h2 className="text-secondary">SHIPX INC.’S OBLIGATIONS</h2>
            <p>
              ALL DELIVERY SERVICES ARRANGED UNDER THE NAME SHIPX INC. ARE
              COMPLETED BY THIRD PARTY INDEPENDENT CONTRACTORS, UNLESS OTHERWISE
              STATED, THAT ARE SELECTED AT THE SOLE DISCRETION OF SHIPX INC. THE
              RELATIONSHIP OF SHIPX INC. AND THE CONTRACTOR IS THAT OF AN
              INDEPENDENT CONTRACTOR. THE CONTRACTOR IS NOT AN AFFILIATE,
              PARTNER, EMPLOYEE, AGENT OR REPRESENTATIVE OF SHIPX INC. SHIPX
              INC. WILL PROVIDE THE CONTRACTOR WITH THE NECESSARY INFORMATION
              TO, AS APPLICABLE, SCHEDULE THE SERVICES, AND TO TIMELY PICK UP
              AND/OR DELIVER THE PRODUCTS AT CUSTOMER’S LOCATION.
            </p>
            <h2 className="text-secondary">CUSTOMER OBLIGATIONS</h2>
            <p>
              UPON ARRIVAL, CUSTOMER SHOULD VERIFY THE IDENTITY OF THE
              CONTRACTOR AND IF THERE IS ANY QUESTION OR CONCERN CONTACT SHIPX
              INC. AT INFO@SHIPX.COM CUSTOMER REQUESTS FOR ADDITIONAL SERVICES,
              CLAIMS, OR ESCALATIONS SHOULD BE DIRECTED TO INFO@SHIPX.COM
              CUSTOMER AGREES THAT SHIPX INC. HAS NO LIABILITY FOR ANY ACTS OR
              OMISSIONS OF THE CONTRACTOR IN THE ACTUAL PERFORMANCE OF THE
              SERVICES AND CUSTOMER, KNOWINGLY AND VOLUNTARILY, WAIVES AND
              RELEASES SHIPX INC. FROM ANY CLAIMS, LIABILITIES, DAMAGES OR
              EXPENSES THAT ARISE OUT OF OR RESULT FROM THE ACTS OR OMISSIONS OF
              THE CONTRACTOR.
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default Termsofserivce
